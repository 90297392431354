<template>
      
  <div class="all">
     
    <div class="header">
      <img src="../assets/headerImg.png" alt="" />
    </div>
    <div class="details">
      <div class="titles">
        <div class="imgs">
        <img src="../assets/titleImg.png" alt="" />

        </div>
        <div class="left">
            意见反馈
        </div>
        <div class="imgs">
        <img src="../assets/titleImg.png" alt="" />

        </div>
      </div>
      <p>
        欢迎您在此留言，您可对顺博参观体验、场馆服务感受、网站使用意见建议等多方面内容留言，
        如您需咨询相关事项，请先参考服务中心的内容，如其中没有您需要的答案，
        可向我们致电，<span class="cla">电话:81475286转1</span>
        ，我们会热情解答您的疑惑
      </p>
      <div class="from">
        <div class="inputs">
          <div class="leftInputs">
            <div class="left">
                姓
            </div>
            <div class="jianju"></div>
            <div class="left">
                名:
            </div> </div>
          <div class="rightInputs">
            <input
              type="text"
              v-model="username"
              placeholder="请输入您的姓名"
            />
          </div>
        </div>
        <div class="inputs">
          <div class="leftInputs">手机号:</div>
          <div class="rightInputs">
            <input
              type="text"
              v-model="phone"
              placeholder="请输入您正确的手机号码"
            />
          </div>
        </div>
        <div class="inputs">
          <div class="leftInputs">
            留
            <div class="jianju"></div>
            
            言:</div>
          <div class="rightInput">
            <textarea
              placeholder="请输入您的宝贵建议"
              v-model="miaoshu"
              name=""
              id=""
              cols="30"
              rows="10"
            >
            </textarea>
          </div>
        </div>
        <div class="btnSubmit">
          <button @click="submit">提交</button>
        </div>
      </div>
    </div>
    <FootGO></FootGO>
    <Dialog :titles="titles" v-if="flag"></Dialog>
  </div>
</template>

<script>
import FootGO from "../components/footer";
import { post, fetch } from '../api/https';
import { jsonp } from 'vue-jsonp'
import Dialog from '../components/Dialog.vue';
// import axios from "axios";
import router from '../router';
export default {
  data() {
    return {
      phone: "",
      username: "",
      miaoshu: "",
      api: { getAptRwy: "/messages/submit" },
      titles:'',
      flag:false,
      num:2
    };
  },
  components: {
    FootGO,
    Dialog,
  },
  created() {},
  mounted() {
    fetch("/getFootMenu").then((res) => {
        document.title = res.data[3].menu_seo_title;

      });
  },
  methods: {
    banner(){
      fetch('/getmenu').then(res=>{
      })
    },
    submit() {
      // 验证中文名称
        function isChinaName(name) {
          var pattern = /^[\u4E00-\u9FA5]{1,6}$/;
          return pattern.test(name);
        }
        
        // 验证手机号
        function isPhoneNo(phone) {
          var pattern = /^1[345789]\d{9}$/;
          return pattern.test(phone);
        } 
        // if(this.username == '' && this.phone == '' && this.miaoshu == ''){
        //   this.showPopupMessage('请完善信息',true)
        //      return false
        // }
        if(!this.username){
            this.showPopupMessage('请输入您的姓名',true)
            return false
      }else if(!isChinaName(this.username) ){
            this.showPopupMessage('您输入的姓名有误',true)
            
             return false
      }else if(!this.phone){
            this.showPopupMessage('请输入您的手机号',true)
            return false
      }else if(!isPhoneNo(this.phone)){
            this.showPopupMessage('您输入的手机号码有误',true)
            return false
      }else if(!this.miaoshu){
            this.showPopupMessage('请输入留言信息',true)
            return false
      }
      
       else{
          var data = {
            name: this.username,
            phone: this.phone,
            content: this.miaoshu,
          };
          post('/messages/submit',data).then((res) => {

                
            this.titles = res.msg
            this.showPopupMessage(this.titles,true)
            this.phone= "",
            this.username= "",
            this.miaoshu= ""
          })
      } 
    },
  },
};
</script>

<style scoped>
.all{
  height: auto;
  width: 100%;
  background-color: #ffffff;
}
.header {
  height: 300px;
  width: 100%;
}
.header img {
  height: 100%;
  width: 100%;
}


.details {
  width: 100%;
  background: url("../assets/detailsImg.png");
  background-size: 100% 100%; 
  box-sizing: border-box;
  text-align: center;
  padding-bottom: 67px;
  margin: 0 auto;

}
.titles {
  padding: 20px;
  text-align: center;
  font-size: 45px;
  color: #384567;
  font-weight: bold;
  line-height: 60px;
  box-sizing: border-box;
  padding-top: 57px;
  padding-bottom: 61px;
font-family: Adobe Heiti Std;
  display: flex;
  /* flex-direction: column;  */
  justify-content: center;
  align-items: center;
}
.titles img{
  width: 26px;
  height: 21px;
  float:left;
  
}
.titles .imgs{
  padding-top: 3px;
  height: 28px;
  width: 26px;
  box-sizing: border-box;
  float: left;
  margin: 0 24px;
}
.jianju{
  width: 23px;
  height: 23px;
  margin: 0; 
  padding: 0;
}
.left{
  float: left;
}
.details p {
  font-size: 24px;
  line-height: 40px;
  color: #888888;
  font-family: PingFang SC;
  font-weight: 345;
  text-align: left;
  width: 900px;
  text-indent: 2em;

  margin:  0 auto;
}
.cla {
  color: #c09961;
}
.from {
  width: 1000px;
  height: 663px;
  background: #ffffff;
  box-shadow: 0px 0px 49px 0px rgba(6, 0, 1, 0.05);
  border-radius: 20px;
  padding: 62px 115px;
  padding-right: 0%;
  margin: 0 auto;
  margin-top: 58px;
  box-sizing: border-box;
}
.inputs {
  margin-bottom: 40px;
  height: 80px;
}
.leftInputs {
  float: left;
  height: 80px;
  width: 80px;
  text-align: center;
  font-size: 23px;
  color: #888888;
  line-height: 80px;
  display: flex;
  justify-content: space-between;
  margin-right: 34px;
  
}
.rightInputs {
  float: left;
  height: 80px;
  width: 679px;
  height: 79px;
  background: #f9f8f8;
  border-radius: 10px;
}
.rightInput {
  float: left;
  height: 80px;
  width: 679px;
  height: 229px;
  background: #f9f8f8;
  border-radius: 10px;
}
.rightInputs input {
  outline: none;
  border: none;
  float: left;
  width: 678px;
  height: 79px;
  background: none;
  font-size: 23px;
  box-sizing: border-box;
  background: none;
  padding-left: 20px;
  font-size: 24px;
font-family: PingFang SC;
font-weight: 500;
}
.rightInputs input::placeholder{
color: #C6C6C6;
}
.rightInputs input{
color: #4d4b4b;
}
.rightInput textarea::placeholder{
  color: #C6C6C6;
}
.rightInput textarea {
  resize: none;
  background: #f9f8f8;
  outline: none;
  border: none;
  float: left;
  width: 678px;
  height: 229px;
  background: none;
  font-size: 23px;
  padding-left: 20px;
  padding-top: 10px;
  line-height: 40px;
  box-sizing: border-box;
  font-family: PingFang-SC-Medium;
}
.btnSubmit button {
  width: 238px;
  height: 53px;
  background: url("../assets/btn.png");
  line-height: 53px;
  text-align: center;
  color: #fff;
  outline: none;
  font-size: 24px;
  margin-top: 45px;
  border: none;
  cursor: pointer;
}
</style>
